@use 'constants' as *;

.mapcontainer {
    margin: 0 auto;
    z-index: 1100 !important;
    .google-map {
        height: 300px;
        width: calc(100% - 2.6rem);
    }
}
.Map {
    .form-group {
        margin: 0 auto 0.5rem;
        input, input:focus { 
            width: calc(100% - 2.6rem); 
            border-top-left-radius: 0.75rem; 
            border-bottom-left-radius: 0.75rem; 
            border: 1px solid $colour_secondary_blue
        }
        // input:focus { 
        //     outline: none; 
        // }
        .btn-location {
            border: 1px solid $colour_secondary_blue;
            border-top-right-radius: 0.75rem;
            border-bottom-right-radius: 0.75rem;
            background-color: $colour_secondary_blue;
            color: white;
            height: 2.6rem;
            width: 2.6rem;
        }
    }
}