/***************************
     Logo colour palette
***************************/
$colour_1: #007da4;
$colour_2: #6996a4;
$colour_3: #9dc6c5;
$colour_4: #ebebeb;
$colour_5: #000000;
$colour_6: #ffffff;
$colour_7: #00afaa;
$colour_8: #33ab5a;
$colour_9: #eb5658;
$colour_0: #ea9a53;
$colour_a: #ebbc2a;
$colour_b: #EFEFEF;
$colour_c: #111111;

$colour_modal_bg_dk: #929ca7;
$colour_control_text_dk: #3a3737;

$colour_page_bg_dk: #121212;
$colour_page_fg_dk: #f2f2f2;

$colour_page_bg_lt: #ebebeb;
$colour_page_fg_lt: #353030;

$colour_card_bg_lt: #ffffff;
$colour_card_bg_dk: #545a5a;
$colour_card_shadow_lt: #aaaaaa;
$colour_card_shadow_dk: #afc9cf;

$colour_card_fg_lt: $colour_c;
$colour_card_fg_dk: $colour_b;

$colour_page_bg: $colour_page_bg_lt;
$colour_page_fg: $colour_page_fg_lt;
$colour_card_shadow: $colour_card_shadow_lt;
$colour_card_bg: $colour_card_bg_lt;
$colour_card_fg: $colour_card_fg_lt;

$colour_select_item_bg_lt: $colour_7;
$colour_select_item_fg_lt: $colour_4;

$colour_select_item_bg_dk: $colour_3;
$colour_select_item_fg_dk: $colour_5;

$colour_status_preint: #00AFAA;
$colour_status_postint: #007DA4;
$colour_status_pendingaccept: #007DA4;
$colour_status_orcareject: #007DA4;
$colour_status_returner: #007DA4;
$colour_status_returnToOrca: #007DA4;
$colour_status_accepted: #007DA4;
$colour_status_applied: #E8BB2A;
$colour_status_rtp: #EA9A53;
$colour_status_placed: #33AB5A;
$colour_status_oop: #E85658;
$colour_status_rejected: #E85658;
$colour_status_transferred: #E8BC2A;

$colour_status_interviewer_active:#33AB5A;
$colour_status_interviewer_inactive:#E85658;

$colour_sidebar_menu: #7B8D93;

/***************************
  Secondary colour palette
***************************/
$colour_secondary_purple: #685C8E;
$colour_secondary_yellow: #E8BC2A;
$colour_secondary_green: #33AB5A;
$colour_secondary_grey: #454D5A;
$colour_secondary_blue: #00AFAA;
$colour_secondary_red: #D42C20;
$colour_secondary_amber: #FFBF00;

$colour_logo_red: #D42C20;
$colour_logo_light_blue: #00AFAA;
$colour_logo_blue: #007DA4;
$colour_logo_dark_grey: #565656;
$colour_logo_light_grey: #A7A7A7;
$colour_logo_white: #FFF;
$colour_logo_black: #000;
$colour_url_link: #50AF9D;

$colour_general_white: #ffffff;
$colour_general_red: #E85658;
$colour_light_light_gray: #cccccc;
$colour_light_gray: #a2a2a2;
/*
 * User Menu Colours
 */
 $colour_account_menu_background: #ffffff;
 $colour_account_menu_border_top: #007da4;
 $colour_account_menu_border_bottom: #007da4;
 $colour_account_menu_text: #007da4;
 $colour_account_menu_separator:  #007da4;

/*
 * Control colours from the new mockups
 */
$colour_applicant_profile_bg_dk: #537D88;
$colour_applicant_profile_fg_dk: white;

$colour_select_item: #007DA4;
$colour_select_item_fg: #D2D7DA;
$colour_unselected_item: #D2D7DA;
$colour_pale_text: #565656;

$colour_title_bar:  #F4F4F4;
//$colour_title_bar:  #D2D7DA;
$colour_title_text: #007DA4;

$top_nav_background: #537d88;
$search_bar_background: #87a4ac;

$colour_focus_control_border_lt: $colour_1;
$colour_focus_control_border_dk: $colour_3;

$colour_navbar_fg_lt: rgb(229, 229, 192);
$colour_navbar_bg_lt: #537d88;

$colour_navbar_fg_dk: rgb(229, 229, 192);
$colour_navbar_bg_dk: rgb(35, 54, 59);

$colour_control_bg_lt: #ebebeb;
$colour_control_bg_dk: #353030;

$colour_btn_primary_default_fg: $colour_6;
$colour_btn_primary_default_bg: #00AEAA;
$colour_btn_primary_hover_bg: #33BEBA;
$colour_btn_primary_active_bg: #008B87;
$colour_btn_primary_disabled_bg: #B3E7E6;

$colour_btn_success_default_fg: $colour_6;
$colour_btn_success_default_bg: #33AB5A;
$colour_btn_success_hover_bg: #5CBB7A;
$colour_btn_success_active_bg: #298847;
$colour_btn_success_disabled_bg: #C2E6CE;

$colour_btn_danger_default_fg: $colour_6;
$colour_btn_danger_default_bg: #E85557;
$colour_btn_danger_hover_bg: #EC7879;
$colour_btn_danger_active_bg: #B94546;
$colour_btn_danger_disabled_bg: #F8CCCD;

$colour_btn_secondary_default_fg: $colour_general_white;
$colour_btn_secondary_default_bg: $colour_secondary_grey;
$colour_btn_secondary_hover_fg: $colour_light_light_gray;
$colour_btn_secondary_hover_bg: $colour_secondary_grey;
$colour_btn_secondary_active_bg: $colour_secondary_grey;
$colour_btn_secondary_disabled_bg: $colour_6;

$colour_btn_modal_pop_default_fg: $colour_8;
$colour_btn_modal_pop_default_bg: white;
$colour_btn_modal_pop_hover_fg: white;
$colour_btn_modal_pop_hover_bg: #5CBB7A;
$colour_btn_modal_pop_active_bg: white;
$colour_btn_modal_pop_disabled_bg: #C2E6CE;

$colour_message_bg_lt_1: #E6F9E4;
$colour_message_bg_lt_2: #DDE7F9;

// Reference Ratings
$colour_rating_1:$colour_8;
$colour_rating_2:$colour_7;
$colour_rating_3:$colour_0;
$colour_rating_4:$colour_9;